import {
  buildThemeHelpers,
  TStyleLambda,
} from './build-theme-helpers';

const VITE_REACT_APP_ENV = import.meta.env.VITE_REACT_APP_ENV;

export const lightTheme = {
  info: {
    name: `light`,
  },
  palette: {
    baseAccentColor        : `#FE8C19`,
    secondaryAccentColor   : `#DA6C12`,
    blackestColor          : `#000`,
    blackColor             : `#222222`,
    blackTransparent85Color: `#262626`,
    lightGrayColor         : `#DADADA`,

    darkBlueColor   : `${VITE_REACT_APP_ENV === `test` ? `#563752` : `#00475D`}`,
    lightBlueColor  : `${VITE_REACT_APP_ENV === `test` ? `#F45681` : `#FE8C19`}`,
    lighterBlueColor: `${VITE_REACT_APP_ENV === `test` ? `#F57CA8` : `#40a9ff`}`,

    transparentBlackColor: `rgba(0, 0, 0, 0.16)`,
    black500Color        : `#333333`,
    blueColor            : `#E9FBFC`,
    blue20Color          : `rgba(45, 156, 219, 0.2)`,
    blue5Color           : `rgba(45, 156, 219, 0.05)`,
    blue500Color         : `#2F80ED`,
    errorColor           : `#FF4D4F`,
    grayColor            : `#656D78`,
    gray5Color           : `rgba(101, 109, 120, 0.05)`,
    greenColor           : `#62B157`,
    green20Color         : `rgba(98, 177, 87, 0.2)`,
    green5Color          : `rgba(98, 177, 87, 0.05)`,
    primaryColor         : `#FE8C19`,
    purpleColor          : `#220084`,
    purple500Color       : `#9B51E0`,
    purple5Color         : `rgba(155, 81, 224, 0.05)`,
    redColor             : `#DB3D40`,
    red5Color            : `rgba(219, 61, 64, 0.05)`,
    orangeColor          : `orange`,
    yellow5Color         : `rgba(230, 210, 0, 0.05)`,
    yellow500Color       : `#E6D200`,

    whiteColor   : `#fff`,
    white170Color: `#F7F7F7`,
    white160Color: `#F6F6F6`,
    white150Color: `#F5F5F5`,
    white100Color: `#f0f0f0`,
    white95Color : `#f2f2f2`,
    white75Color : `#BFBFBF`,
    white60Color : `#999999`,
    white5Color  : `rgba(255, 255, 255, 0.05)`,

    primary800Color: `#141516`,
    primary700Color: `#202122`,
    primary600Color: `#DA6C12`,
    primary500Color: `#FE8C19`,
    primary400Color: `#FEB152`,
    primary100Color: `#FEF1D0`,
    primary5Color  : `#FFF9F4`,

    secondary600Color: `#5F8BBB`,
    secondary500Color: `#82B1DA`,
    secondary400Color: `#A2CBE8`,
    secondary100Color: `#E8F7FD`,
    secondary5Color  : `#F9FBFD`,

    black100Color: `#141516`,
    black80Color : `#292a2b`,
    black60Color : `#3e3f40`,
    black50Color : `#545455`,
    black45Color : `#69696a`,
    black40Color : `#7e7f7f`,
    black35Color : `#939494`,
    black30Color : `#a8a9a9`,
    black20Color : `#bebebe`,
    black10Color : `#d3d3d3`,
    black5Color  : `#e8e8e8`,

    alert600Color: `#DB3D40`,
    alert500Color: `#FF5F54`,
    alert400Color: `#FF917E`,
    alert100Color: `#FFEFEE`,
    alert5Color  : `#FFF7F6`,

    backgroundColor      : `#FFFFFF`,
    backgroundAccentColor: `${VITE_REACT_APP_ENV === `test` ? `#563752` : `#141516`}`,

    textPrimaryColor  : `#141516`,
    textSecondaryColor: `#3e3f40`,
    textDisabledColor : `rgba(0, 0, 0, 0.25)`,

    borderDefaultColor: `#e8e8e8`,
    borderAccentColor : `#d3d3d3`,
  },
  breakpoints: {
    xs : `360px`,
    sm : `540px`,
    md : `768px`,
    mm : `1000px`,
    lg : `1200px`,
    xl : `1346px`,
    xxl: `1800px`,
  },
  shadows: {
    basic: `0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08)`,
    card : `0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #C0CDD91F, 0px 4px 8px 0px #C0CDD92E`,
    // eslint-disable-next-line max-len
    image: `0px 4px 8px rgba(192, 205, 217, 0.18), 0px 0px 2px rgba(192, 205, 217, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.04)`,
  },
  zIndex: {
    header   : `5`,
    subheader: `3`,
  },
  height: {
    headerMobile: `54px`,
    headerTablet: `64px`,
  },
};

export const darkTheme = {
  ...lightTheme,
  info: {
    name: `dark`,
  },
  palette: {
    ...lightTheme.palette,

    primary600Color: `#DA6C12`,
    primary500Color: `rgba(218, 108, 18, 0.8)`,
    primary400Color: `rgba(218, 108, 18, 0.4)`,
    primary100Color: `rgba(218, 108, 18, 0.1)`,
    primary5Color  : `rgba(218, 108, 18, 0.05)`,

    alert600Color: `#DB3D40`,
    alert500Color: `rgba(219, 61, 64, 0.8)`,
    alert400Color: `rgba(219, 61, 64, 0.4)`,
    alert100Color: `rgba(219, 61, 64, 0.3)`,
    alert5Color  : `rgba(219, 61, 64, 0.1)`,

    black100Color: `#E2E3E3`,
    black80Color : `#C5C5C5`,
    black60Color : `#C5C5C5`,
    black40Color : `#99999A`,
    black30Color : `#6C6D6E`,
    black10Color : `#2C2D2E`,
    black5Color  : `#202122`,

    backgroundColor      : `#141516`,
    backgroundAccentColor: `${VITE_REACT_APP_ENV === `test` ? `#202122` : `#202122`}`,

    textPrimaryColor  : `#E2E3E3`,
    textSecondaryColor: `#99999A`,
    textDisabledColor : `rgba(255, 255, 255, 0.25)`,

    borderDefaultColor: `#2C2D2E`,
    borderAccentColor : `#38393A`,
  },
  shadows: {
    ...lightTheme.shadows,
    // eslint-disable-next-line max-len
    image: `0px 4px 8px rgba(30, 35, 66, 0.18), 0px 0px 2px rgba(30, 35, 66, 0.12), 0px 0px 1px rgba(30, 35, 66, 0.04)`,
  },
};

export type TTheme = typeof lightTheme;

// eslint-disable-next-line
declare module 'styled-components' {
  export interface DefaultTheme extends TTheme {}
}

export const getLightStyleOr = (
  lightStyle: TStyleLambda,
  darkStyle: TStyleLambda,
) => (props: any) => {
  if (props.theme.info.name === `light`) {
    return () => lightStyle;
  }
  return () => darkStyle;
};

const {
  breakpoints,
  height,
  info,
  palette,
  shadows,
  zIndex,
} = buildThemeHelpers(lightTheme);

export { info, palette, breakpoints, shadows, zIndex, height };
